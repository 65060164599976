<template>
	<div>
		<v-container>
			<v-row>
				<v-col cols="12" class="text-center">
					<h1>
						I.O.P. - Acties & Borging
					</h1>
					<v-divider></v-divider>
				</v-col>
			</v-row>


			<v-row>
				<v-col cols="12">
					<p class="font-weight-regular">
						Wat vraagt deze ontwikkeling van jou? Wat ga je precies doen? Wat is jouw rol en jouw verantwoordelijkheid? Hoe pak je de belemmeringen aan? Hoe ga je bijv. jouw vaste gewoonten overwinnen? Wat heb je hiervoor nodig? Hoe zorg jij ervoor dat de ontwikkeling ook geborgd wordt en dat jij je blijft inzetten voor de voortgang?
					</p>
					<p class="font-weight-regular">
						Hieronder kun je (SMART) jouw invulling geven.
					</p>
				</v-col>

				<v-col cols="12" class="text-center py-0">
					<v-textarea
						v-model="form.row_1"
						label="1"
						rows="1"
						auto-grow
						required
						:counter="191"
						:rules="[v => (v && v.length <= 191) || 'Deze tekst is te lang!']"
					/>
					<v-textarea
						v-model="form.row_2"
						label="2"
						rows="1"
						auto-grow
						required
						:counter="191"
						:rules="[v => (v && v.length <= 191) || 'Deze tekst is te lang!']"
					/>
					<v-textarea
						v-model="form.row_3"
						label="3"
						rows="1"
						auto-grow
						required
						:counter="191"
						:rules="[v => (v && v.length <= 191) || 'Deze tekst is te lang!']"
					/>
					<v-textarea
						v-model="form.row_4"
						label="4"
						rows="1"
						auto-grow
						required
						:counter="191"
						:rules="[v => (v && v.length <= 191) || 'Deze tekst is te lang!']"
					/>
					<v-textarea
						v-model="form.row_5"
						label="5"
						rows="1"
						auto-grow
						required
						:counter="191"
						:rules="[v => (v && v.length <= 191) || 'Deze tekst is te lang!']"
					/>
					<v-textarea
						v-model="form.row_6"
						label="6"
						rows="1"
						auto-grow
						required
						:counter="191"
						:rules="[v => (v && v.length <= 191) || 'Deze tekst is te lang!']"
					/>
					<v-textarea
						v-model="form.row_7"
						label="7"
						rows="1"
						auto-grow
						required
						:counter="191"
						:rules="[v => (v && v.length <= 191) || 'Deze tekst is te lang!']"
					/>
					<v-textarea
						v-model="form.row_8"
						label="8"
						rows="1"
						auto-grow
						required
						:counter="191"
						:rules="[v => (v && v.length <= 191) || 'Deze tekst is te lang!']"
					/>
				</v-col>
			</v-row>
		</v-container>

		<footer-i-o-p :on-save="onSave" nextRoute="dashboardDevelopment" next-button-text="Ontwikkelen" />
	</div>
</template>

<script>

import FooterIOP from "@/pages/dashboard/wise/footer";
import apiClient from "@/services/apiClient";

export default {
	name: "dashboardWiseIOPEnd",
	components: {FooterIOP},
	data: () => ({
		form: {
			type: 4,
			row_1: null,
			row_2: null,
			row_3: null,
			row_4: null,
			row_5: null,
			row_6: null,
			row_7: null,
			row_8: null,
		},
	}),
	mounted() {
		apiClient.get("iop/get", {params: {type: 4}}).then(response => {
			this.form.row_1 = response.data.row_1;
			this.form.row_2 = response.data.row_2;
			this.form.row_3 = response.data.row_3;
			this.form.row_4 = response.data.row_4;
			this.form.row_5 = response.data.row_5;
			this.form.row_6 = response.data.row_6;
			this.form.row_7 = response.data.row_7;
			this.form.row_8 = response.data.row_8;
		}).catch(error => {
			this.undefinedError();
		});
	},
	methods: {
		onSave() {
			return new Promise((resolve, reject) => {
				apiClient.post("iop/save", this.form).then(response => {
					resolve();
				}).catch(error => {
					reject();
				});
			});
		}
	}
};
</script>
