<template>
  <div>

    <div :style="menuBaseStyle" class="">
      <v-row no-gutters>
        <v-col cols="5" class="px-6 cursor-pointer" @click="this.save">
          <v-btn color="accent" block :loading="loadingSave">Opslaan</v-btn>
        </v-col>

        <v-col cols="7" class="px-6 cursor-pointer" @click="goToNext">
          <v-btn color="accent" block :loading="loadingNext">{{ this.nextButtonText }}</v-btn>
        </v-col>
      </v-row>
    </div>

    <snackbar-text text="Opgeslagen" v-model="showSaved" />
    <snackbar-text :text="error.message" bg-color="red" text-color="white" v-model="error.show" />
  </div>
</template>

<script>


import SnackbarText from "@/components/snackbar-text";

export default {
  name: "FooterIOP",
  components: {SnackbarText},
  props: {
    nextRoute: {
      type: String,
      required: true
    },
    nextButtonText: {
      type: String,
      required: true
    },
    onSave: {
      type: Function,
      required: true
    },
  },
  data() {
    return {
      menuBaseStyle: {
        'background-color': this.$vuetify.theme.themes.light.white,
        'height': '60px',
        'padding-top': '10px',
        'position': 'fixed',
        'bottom': '5px',
        'width': '100%',
        'z-index': '1000',
      },
      menuBaseLogoStyle: {
        'height': '40px'
      },
      loadingSave: false,
      loadingNext: false,
      error: {
        show: false,
        message: ""
      },
      showSaved: false,
    }
  },
  methods: {
    save() {
      this.loadingSave = true;

      this.onSave().then(() => {
        this.showSaved = true;
        this.loadingSave = false;
      }).catch(() => {
        this.undefinedError();
      })
    },
    goToNext() {
      this.loadingNext = true;

      this.onSave().then(() => {
        this.showSaved = true;
        this.loadingNext = false;

        setTimeout(() => {
          this.$router.push({name: this.nextRoute})
        }, 1000);
      }).catch(() => {
        this.undefinedError();
      })
    },
    undefinedError() {
      this.error.message = "Er is een onbekende fout opgetreden.";
      this.error.show = true;
      this.loadingSave = false;
      this.loadingNext = false;
    }
  }
};
</script>

<style scoped>
.text-blue {
  color: #004572;
}
</style>